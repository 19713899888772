import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import Gallery from '../components/Gallery';
import { PageHeader, PageHeaderContent } from '../styled';

const GalleryPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet title={`${data.wordpressPage.title} - Eddy Herrera`} />
    <PageHeader padding="0" overflow="hidden">
      <Img
        sizes={data.wordpressPage.featured_media.localFile.childImageSharp.sizes}
        alt={data.wordpressPage.featured_media.alt_text}
      />
      <PageHeaderContent>
        <Container>
          <Row>
            <Column>
              <h1>{data.wordpressPage.title}</h1>
            </Column>
          </Row>
        </Container>
      </PageHeaderContent>
    </PageHeader>
    <Container>
      <Row columns="3">
        {data.allWordpressWpGallery.edges.map(gallery => (
          <Column sm={4}>
            <Gallery key={gallery.node.id} image={gallery.node.featured_media} images={gallery.node.acf.images}>
              {gallery.node.title}
            </Gallery>
          </Column>
        ))}
      </Row>
    </Container>
  </Layout>
);

export const query = graphql`
  query GalleryPageQuery {
    wordpressPage(slug: { eq: "galeria" }) {
      title
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            sizes(maxWidth: 1440) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
    allWordpressWpGallery {
      edges {
        node {
          id
          title
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          acf {
            images {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1024) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GalleryPage;

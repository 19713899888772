import React, { Component, Fragment } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Lightbox from 'react-images';

import { Card, CardContent } from '../../styled';

const ImageWrapper = styled.div`
  height: 200px;
  overflow: hidden;
`

class Gallery extends Component {
  state = {
    isLightboxOpen: false,
    currentImage: 0,
  }

  closeLightbox = () => {
    this.setState({
      isLightboxOpen: false,
    })
  }

  openLightbox = () => {
    this.setState({
      isLightboxOpen: true,
    })
  }

  goToNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  goToPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  render() {
    // console.log(this.props.images)
    // images={gallery.node.acf.images.map(image => image.localFile.childImageSharp.sizes.src)}
    const images = this.props.images.map(image => (
      { src: image.localFile.childImageSharp.sizes.src }
    ));

    return (
      <Fragment>
        <Card onClick={this.openLightbox}>
          {this.props.image && (
            <ImageWrapper>
              <Img sizes={this.props.image.localFile.childImageSharp.sizes} />
            </ImageWrapper>
          )}
          <CardContent>{this.props.children}</CardContent>
        </Card>
        <Lightbox images={images} currentImage={this.state.currentImage} isOpen={this.state.isLightboxOpen} onClose={this.closeLightbox} onClickNext={this.goToNext} onClickPrev={this.goToPrevious} imageCountSeparator=" de " />
      </Fragment>
    )
  }
};

export default Gallery;
